import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import yeti from "./yeti";
//import de from 'vuetify/es5/locale/de';

Vue.use(Vuetify);

const sassVariables = yeti.sassVariables();

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: sassVariables["theme-primary"],
        secondary: sassVariables["theme-secondary"],
        accent: sassVariables["theme-accent"],
        error: sassVariables["theme-error"],
        info: sassVariables["theme-info"],
        success: sassVariables["theme-success"],
        warning: sassVariables["theme-warning"]
      },
    },
  },
  lang: {
    //locales: { de },
    //current: 'de',
  },
  icons: {
    iconfont: 'fa'
  },
});
