import Vue from "vue";
import vuetify from "../vuetify.js";
import Tabs from "../components/tabs/Tabs.vue";
import ExposeTab from "../components/tabs/ExposeTab.vue";

export default () => {
  const elements = document.querySelectorAll(".vue-tabs");

  if (elements) {
    elements.forEach((el) => {
      let entry = Tabs;

      if (el.dataset.entry) {
        switch(el.dataset.entry) {
          case "Expose":
            entry = ExposeTab;
            break;
        }
      }

      new Vue({
        el,
        vuetify,

        computed: {
          tabs() {
            let tabs = {};
            if (el.dataset.tabs) {
              tabs = JSON.parse(el.dataset.tabs);
            }

            return tabs;
          }
        },

        render: h => h(entry)
      })
    });
  }
};
