import Vue from "vue";
import Mainnavigation from "../components/mainnavigation/Mainnavigation.vue";
import DropdownNavigation from "../components/mainnavigation/DropdownNavigation.vue";
import DropdownTitle from "../components/mainnavigation/DropdownTitle.vue";
import DropdownLinks from "../components/mainnavigation/DropdownLinks.vue";
import DropdownClosebtn from "../components/mainnavigation/DropdownClosebtn.vue";

export default function() {
    const element = document.querySelector("#vue-mainnavigation");
    if (element) {
        new Vue({
            el: element,
            template: element,
            components: {
                Mainnavigation,
                DropdownNavigation,
                DropdownTitle,
                DropdownLinks,
                DropdownClosebtn
            },
            created() {
                this.$yeti = window.$yeti;
            }
        });
    }
}
