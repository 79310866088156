<template>
<ul class="list-unstyled navigation">
    <li :class="{ active: link.active }" v-for="(link, idx) in links" :key="idx"><a :href="link.href">{{ link.text }}</a></li>
</ul>
</template>

<script>
export default {
  name: "DropdownLinks",

  computed: {
    links() {
      return this.$parent.dropdownLinks;
    }
  }
};
</script>
