<template>
<a v-if="href" :href="href">{{ text }}</a>
</template>

<script>
export default {
  name: "DropdownTitle",

  computed: {
    text() {
      return this.$parent.dropdownTitle.text;
    },

    href() {
      return this.$parent.dropdownTitle.href;
    }
  }
};
</script>
