<template>
<div>

</div>
</template>

<script>
export default {
  name: "Tabs",

}
</script>
