export default () => {
  const mobileMenu = document.querySelectorAll(".sw-menu-icon");
  if (!mobileMenu) {
    return;
  }

  mobileMenu.forEach((elm) => {
    const icon = elm.querySelector("i");
    icon.addEventListener("click", (icon) => {
      icon.preventDefault();

      const menu = document.querySelector(elm.dataset.collapse);
      if (!menu) {
        return;
      }

      menu.classList.toggle("collapsed");
    });
  });
}
