import Vue from "vue";
import vuetify from "../vuetify.js";
import Accordion from "../components/accordion/Accordion.vue";

export default () => {
  const elements = document.querySelectorAll(".vue-accordion");

  if (elements) {
    elements.forEach((el) => {
      new Vue({
        el,
        vuetify,

        computed: {
          content() {
            let content = {};
            if (el.dataset.content) {
              content = JSON.parse(el.dataset.content);
            }

            return content;
          }
        },

        render: h => h(Accordion)
      })
    });
  }
};
