<template>
<a @click.prevent="accept"><slot></slot></a>
</template>

<script>
export default {
  name: "CookieNoticeLink",

  methods: {
    accept() {
      this.$parent.accept();
    }
  }
}
</script>
