<template>
<v-app>
  <v-expansion-panels>
    <v-expansion-panel
      v-for="(item,i) in content"
      :key="i"
    >
      <v-expansion-panel-header>
          {{ item.title }}
      </v-expansion-panel-header>

      <v-expansion-panel-content>
        <div v-html="item.content"></div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</v-app>
</template>

<script>
export default {
  name: "Accordion",

  computed: {
    content() {
      return this.$root.content;
    }
  },
}
</script>
