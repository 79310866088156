<template>
<div>
    <slot></slot>
</div>
</template>

<script>
export default {
  name: "Mainnavigation",

  mounted() {
    // navbar-toggler
    this.navbarToggler = document.querySelector("button.navbar-toggler");
    if (this.navbarToggler) {
      this.navbarToggler.onclick = (e) => {
        e.preventDefault();

        const elm = document.querySelector(this.navbarToggler.getAttribute("data-target"));
        elm.classList.toggle(this.navbarToggler.getAttribute("data-toggle"));
        elm.classList.toggle("collapsed");
      };
    }

    // fullscreen and fixed navigation
    this.headerElm = document.querySelector(".header");

    if (this.headerElm) {
      const innerHeight = this.headerElm.offsetHeight + 50;

      window.addEventListener("scroll", () => {
        const scrollPosY = window.pageYOffset || document.body.scrollTop;

        if (scrollPosY > innerHeight) {
          this.headerElm.classList.add("fixed");
        } else if (scrollPosY <= innerHeight) {
          this.headerElm.classList.remove("fixed");
        }
      }, false);
    }
  },

  methods: {
    isDropdownDisabled() {
      if (this.navbarToggler) {
        // hamburger button is visible
        if (this.navbarToggler.offsetParent) {
          return true;
        }
      }

      return false;
    }
  }
};
</script>
