import Vue from "vue";
import vuetify from "../vuetify.js";
import Modal from "../components/modal/Modal.vue";

export default () => {
  const elements = document.querySelectorAll(".vue-modal");

  if (elements) {
    elements.forEach((el) => {
      let entry = Modal;

      new Vue({
        el,
        vuetify,

        computed: {
          name() {
            let name = "";
            if (el.dataset.name) {
              name = el.dataset.name;
            }

            return name;
          },

          title() {
            let title = "";
            if (el.dataset.title) {
              title = el.dataset.title;
            }

            return title;
          },

          content() {
            let content = {};
            if (el.dataset.content) {
              content = JSON.parse(el.dataset.content);
            }

            return content;
          }
        },

        render: h => h(entry)
      })
    });
  }
};
