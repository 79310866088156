// ACHTUNG!! Muss JSON sein, keine Komentare!
export default {
  "entrypoints": {
    "contact": {"php": false},
    "navigation": {"php": false},
    "cookie-notice": {"php": false},
    "swiper": {"php": false},
    "accordion": {"php": false},
  }
}
