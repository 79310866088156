/* eslint-disable */

export default {
  googleAnalytics(userId) {
    const s = document.createElement("script");
    s.type = "text/javascript";
    s.async = true;
    s.src = "https://www.google-analytics.com/analytics.js";
    document.getElementsByTagName("head")[0].appendChild(s);

    window.ga=window.ga||function(){(ga.q=ga.q||[]).push(arguments)};ga.l=+new Date;
    window.ga("create", userId, "auto");
    window.ga("set", "anonymizeIp", !0);
    window.ga("send", "pageview");
  },

  googleAds(tagId) {
    const s = document.createElement("script");
    s.type = "text/javascript";
    s.async = true;
    s.src = "https://www.googletagmanager.com/gtag/js?id=" + tagId;
    document.getElementsByTagName("head")[0].appendChild(s);

    window.dataLayer = window.dataLayer || [];
    window.gtag = window.gtag || function gtag() { dataLayer.push(arguments); }
    window.gtag("js", new Date());
    window.gtag("config", tagId);
  },

  facebookPixel(pixelId) {
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', pixelId);
    fbq('track', 'PageView');
  }
}
