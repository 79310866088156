import deps from "../depsloader";

export default () => {
  const elms = document.querySelectorAll(".swiper-container");
  if (!elms || elms.length == 0) {
    return;
  }

  deps.load("swiper").then((swiper) => {
    const Swiper = swiper.Swiper;
    elms.forEach((elm) => {
      let config = {};

      if (elm.dataset.config) {
        config = JSON.parse(elm.dataset.config);
        elm.removeAttribute("data-config");
      }

      new Swiper(elm, config);
    });
  });
}
