<template>
<span v-if="show" @click="close()" class="fas fa-times header__dropdown-close"></span>
</template>

<script>
export default {
  name: "DropdownClosebtn",

  data() {
    return {
      show: true
    };
  },

  mounted() {
    this.show = this.$yeti.isTouchDevice;
  },

  methods: {
    close() {
      this.$parent.close();
    }
  }
};
</script>
