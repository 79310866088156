<template>
<div class="explorer-warning" v-if="detectIE">
  <span class="fas fa-exclamation-triangle" @click="toggleIeWarning"></span>

  <div v-if="showPopup" class="explorer-warning__content">
    <p class="display-1">Wussten Sie schon?</p>
    <p>... dass Sie mit einem modernen Browser deutlich schneller und sicherer unterwegs sind?</p>
    <p>Zum Beispiel mit
      <a href="https://www.microsoft.com/de-de/windows/microsoft-edge" title="Microsoft Edge" target="_blank" rel="noopener" class="text-normal">Microsoft Edge</a>,
      <a href="https://google.com/chrome" title="Google Chrome" target="_blank" rel="noopener" class="text-normal">Google Chrome</a>,
      <a href="https://www.mozilla.org/firefox" title="Mozilla Firefox" target="_blank" rel="noopener" class="text-normal">Mozilla Firefox</a>.
    </p>

    <span class="fas fa-times-circle" @click="toggleIeWarning"></span>
  </div>
</div>
</template>

<script>
import "./ExplorerWarning.scss";

export default {
    name: "ExplorerWarning",

    data() {
      return {
        showPopup: true
      };
    },

    computed: {
      detectIE() {
        const ua = window.navigator.userAgent;

        // IE 10
        const msie = ua.indexOf("MSIE ");
        if (msie > 0) {
          return true;
        }

        // IE 11
        var trident = ua.indexOf("Trident/");
        if (trident > 0) {
          return true;
        }

        return false;
      }
    },

    methods: {
      toggleIeWarning() {
        this.showPopup = !this.showPopup;
      }
    }
}
</script>
