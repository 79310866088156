<template>
<p class="text-small contact__loggedin" v-if="loggedIn">
  Angemeldet als: {{ username }}
</p>
</template>

<script>
export default {
  name: "ContactLoggedIn",

  computed: {
    loggedIn() {
      if (this.$store && this.$store.state) {
        return this.$store.state.user.loggedIn;
      }

      return false;
    },

    username() {
      return this.$store.state.user.email;
    }
  }

};
</script>

