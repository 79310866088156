<template>
<div>
  <div :style="cstyle" ref="map"></div>
  <slot v-if="map"></slot>
</div>
</template>

<script>
import depsloader from "../depsloader";

let L;

export default {
  name: "StandardMap",

  props: [
    "value",
    "height",
    "zoom",
  ],

  data() {
    return {
      map: null,
      url: this.$yeti.osmTileServer(),
      initialized: false
    }
  },

  computed: {
    cstyle() {
      const s = {};

      if (this.height) {
        s.height = this.height;
      } else {
        s.height = "15rem";
      }

      s.zIndex = 1;

      return s;
    },

    czoom() {
      if (this.zoom) {
        return this.zoom;
      }

      return 16;
    }
  },

  watch: {
    value(val) {
      if (val) {
        this.map.setView(val, this.czoom);
      }
    },

    zoom(val) {
      if (this.map) {
        this.map.setZoom(val);
      }
    }
  },

  created() {
    depsloader.load("leaflet").then((leaflet) => {
      L = leaflet;
      this.map = L.map(this.$refs.map);

      const basemap = L.tileLayer(this.url, {
        attribution: "OpenStreetMap-Mitwirkende"
      });

      basemap.addTo(this.map);

      this.map.on("click", (e) => {
        this.click(e);
      });

      const company = this.$yeti.company();

      this.$emit("initialized", {
        map: this.map,
        L,
        defaultCenter: [company.lat, company.lng]
      });
    });
  },

  methods: {
    updateCenter() {
      /*
      if (this.initialized) {
        this.map.panTo(new L.LatLng(this.center));
      } else {
        let center = this.center;
        if (this.center) {
          this.map.setView(this.center, this.czoom);
        } else {
          const geo = this.getDefaultLatLng();
          this.map.setView([geo.lat, geo.lng], this.czoom);
        }


        this.initialized = true;
      }
      */
    },

    click(event) {
      this.$emit("click", event);
    }
  }
};
</script>
