/*
Diese Datei landet in der app.js, d.h. keine vuetify-Komponenten laden. Dafür den depsloader verwenden.
*/
import Vue from "vue";
import LoginApp from "./components/LoginApp.vue";

const element = document.querySelector("#yeti-module-login");
if (element) {
  window.depsloader(["vuetify", "vuetify-form", "vue-i18n"], function(vuetify, vuetifyForm, i18n) {
    new Vue({
      el: element,
      template: element,
      vuetify,
      i18n,
      components: {
        LoginApp
      }
    });
  });
}
