<template>
<div v-if="load" class="dropdown bounceIn">
    <transition name="slide-fade">
    <div v-show="show" ref="dropdown" :class="{ 'is-touch': isTouchDevice }" @mouseover="triggerShow" @mouseout="triggerClose">
        <slot></slot>
    </div>
    </transition>
</div>
</template>

<script>
export default {
  name: "DropdownNavigation",

  data() {
    return {
      isTouchDevice: false,
      show: false,
      init: false,
      closeTimeout: null,
      dropdownTitle: null,
      dropdownLinks: []
    };
  },

  computed: {
    load() {
      if (this.show) {
        return true;
      }

      if (this.init) {
        return true;
      }

      return false;
    }
  },

  mounted() {
    if (this.show) {
      this.init = true;
    }

    //let list = document.querySelectorAll("#bs4navbar a[data-toggle='dropdown']")
    const list = document.querySelectorAll("#bs4navbar a");
    list.forEach((elm) => {
      elm.onmouseover = (e) => {
        e.preventDefault();

        this.debug("mouseover");

        if (elm.getAttribute("data-toggle")) {
          this.dropdownLinks = [];

          const firstItem = {
            text: elm.innerText,
            href: elm.getAttribute("href"),
            active: elm.parentNode.className.indexOf("active") != -1
          };

          this.dropdownTitle = firstItem;
          const linkElms = elm.parentNode.querySelector(".dropdown-menu").getElementsByTagName("a");

          Array.from(linkElms).forEach((a) => {
            const item = {
              text: a.innerText,
              href: a.getAttribute("href"),
              active: a.parentNode.className.indexOf("active") != -1
            };

            this.dropdownLinks.push(item);
          });

          if (!elm.parentNode.querySelector(".dropdown")) {
            elm.parentNode.appendChild(this.$el);
          }

          this.triggerShow();
        } else {
          this.debug("mouseover, close");
          this.close();
        }
      };

      elm.onmouseout = (e) => {
        e.preventDefault();
        this.triggerClose();
      };

      if (elm.getAttribute("data-toggle")) {
        // sonst kann man auf dem Ipad nicht mehr klicken
        elm.onclick = (e) => {
          if (!this.$parent.isDropdownDisabled() && this.$yeti.isTouchDevice) {
            e.preventDefault();
            this.isTouchDevice = true;
          }
        };
      }
    });

    this.mouseMoveEvent = (e) => {
      this.onMouseMove(e);
    };

    this.clickEvent = (e) => {
      this.onClick(e);
    };
  },

  methods: {
    debug(m) { // eslint-disable-line
      //console.log(m);
    },

    getDropdownElement() {
      this.dropdownElement = document.querySelector("div.header__dropdown");
      if (!this.dropdownElement) {
        // console.log("header__dropdown not found");
      }

      return this.dropdownElement;
    },

    onMouseMove(e) {
      const elm = this.getDropdownElement();
      const y = elm.offsetTop + elm.offsetHeight;

      if (e.clientY - y > 200) {
        this.debug("mousemove close");
        this.close();
      }
    },

    onClick(e) {
      const elm = this.getDropdownElement();
      const y = elm.offsetTop + elm.offsetHeight;

      if (e.clientY - y > 50) {
        this.debug("click close");
        this.close();
      }
    },

    triggerShow() {
      if (this.$parent.isDropdownDisabled()) {
        return;
      }

      if (this.closeTimeout) {
        this.debug("clear timeout");
        clearTimeout(this.closeTimeout);
        this.closeTimeout = null;
      }

      if (!this.show) {
        window.addEventListener("mousemove", this.mouseMoveEvent);
        window.addEventListener("click", this.clickEvent);
      }

      this.show = true;
    },

    close() {
      if (this.show) {
        window.removeEventListener("mousemove", this.mouseMoveEvent);
        window.removeEventListener("click", this.clickEvent);
      }

      if (this.closeTimeout) {
        clearTimeout(this.closeTimeout);
        this.closeTimeout = null;
      }

      this.show = false;
    },

    triggerClose() {
      if (this.closeTimeout) {
        clearTimeout(this.closeTimeout);
        this.closeTimeout = null;
        return;
      }

      this.closeTimeout = setTimeout(() => {
        this.debug("closeTimeout()");
        this.close();
      }, 500);
    }
  }
};
</script>
