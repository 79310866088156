import Vue from "vue";
import vuetify from "../vuetify.js";
import CookieNotice from "../components/cookie-notice/CookieNotice.vue";
import depsloader from "../depsloader";

export default () => {
  const element = document.querySelector("#vue-cookie-notice");
  if (element) {
    depsloader.load("vue-i18n").then((vueI18n) => {
      Vue.use(vueI18n.VueI18n);

      let vueArgs = {};

      vueArgs = {...vueArgs, ...{
        el: element,

        components: {
          CookieNotice
        },

        vuetify,
        i18n: new vueI18n.VueI18n(vueI18n.options),

        render: h => h(CookieNotice)
      }};

      new Vue(vueArgs)
    });
  }
}
